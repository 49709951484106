import { Controller } from "react-hook-form";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";

const SelectInput = ({
  name,
  control,
  label,
  options,
  defaultValue = "",
  rules = {},
  disabled = false,
}) => {
  return (
    <FormControl fullWidth variant="outlined" error={!!rules.error}>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <>
            <Select {...field} label={label} disabled={disabled}>
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{error ? error.message : null}</FormHelperText>
          </>
        )}
      />
    </FormControl>
  );
};

export default SelectInput;
