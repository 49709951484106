import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import TextInput from "../../components/textInput";
import ButtonComponent from "../../components/button";
import ToastNotification from "../../components/toastNotification";
import { Typography, Link, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { logout, setUserData } from "../../redux/slices/userSlice";

const schema = yup.object().shape({
  email: yup.string().email("Email inválido").required("El email es requerido"),
  password: yup.string().required("La contraseña es requerida")
});

const Login = () => {
  const [toastMessage, setToastMessage] = useState("");
  const [toastOpen, setToastOpen] = useState(false);
  const [toastSeverity, setToastSeverity] = useState("info");
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: ""
    }
  });
  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  const handleFormSubmit = (formData) => {
    const { email, password } = formData;

    if (email === "contabilidad@intelnova.co" && password === "N4v4b!ll1n6") {
      dispatch(
        setUserData({
          email: email
        })
      );

      setToastSeverity("success");
      setToastMessage("Inicio de sesión exitoso.");
      setToastOpen(true);

      setTimeout(() => {
        navigate("/BusinessUnit");
      }, 1000);
    } else {
      setToastSeverity("error");
      setToastMessage("Credenciales incorrectas. Por favor, intenta de nuevo.");
      setToastOpen(true);
    }
  };

  return (
    <div className="login-container a-center j-cente">
      <Typography variant="h3" className="login-title">
        NovaBilling
      </Typography>
      <form onSubmit={handleSubmit(handleFormSubmit)} className="mt-15">
        <TextInput name="email" control={control} label="Email" />
        <div>
          <TextInput
            name="password"
            control={control}
            label="Contraseña"
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label={
                    showPassword ? "hide the password" : "display the password"
                  }
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
        <div className="vm-15 j-center">
          <ButtonComponent
            type="submit"
            variant="contained"
            backgroundColor="#1976d2"
            textColor="#fff"
          >
            Ingresar
          </ButtonComponent>
        </div>
      </form>

      <ToastNotification
        open={toastOpen}
        handleClose={() => setToastOpen(false)}
        message={toastMessage}
        severity={toastSeverity}
      />
    </div>
  );
};

export default Login;
