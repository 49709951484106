import React, { useEffect, useState } from "react";
import ModalComponent from "../../components/modalComponent";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextInput from "../../components/textInput";
import SelectInput from "../../components/selectInput";
import CreateCountryModal from "./country/CreateCountryModal";
import CreateBillingUnitModal from "./billingUnit/createBillingUnit";
import {
  status_select,
  transformToSelectData
} from "../../utils/selectsValues";
import {
  billingUnitsCreateOrUpdate,
  callApiAsync,
  countryCreateOrUpdate,
  getBillingUnits,
  getCountrys,
  getSysEnterprise,
  getCurrency
} from "../../services/apiService";
import ButtonComponent from "../../components/button";
import icons from "../../constants/icons";
import SearchableSelectInput from "../../components/selectInput/searchableSelectInput";

function transformCurrencyData(currencyData) {
  return currencyData.map((item) => ({
    value: item.id,
    label: item.siglas.trim()
  }));
}

const CreateEditBusinessUnitModal = ({
  rowData,
  open,
  onClose,
  onSubmitFuntion
}) => {
  const [openCreate, setOpenCreate] = useState(false);
  const [openCreateBillingUnit, setOpenCreateBillingUnit] = useState(false);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [countrys, setCountrys] = useState([]);
  const [billingUnits, setBillingUnits] = useState([]);
  const [sysEnterpriseSelect, setSysEnterpriseSelect] = useState([]);

  const getCountrysSelect = () => {
    callApiAsync(getCountrys, (data) => {
      const countrySelectData = transformToSelectData(data);
      setCountrys(countrySelectData);
    });
  };

  const getSysEnterpriseSelect = () => {
    callApiAsync(getSysEnterprise, (data) => {
      const selectData = transformToSelectData(data);
      setSysEnterpriseSelect(selectData);
    });
  };

  const getBillingUnitsSelect = () => {
    callApiAsync(getBillingUnits, (data) => {
      const billingUnitSelectData = transformToSelectData(data);

      setBillingUnits(billingUnitSelectData);
    });
  };

  useEffect(() => {
    getCountrysSelect();
    getBillingUnitsSelect();
    getSysEnterpriseSelect();
    getCurrencySelect();
  }, []);

  const createSchema = yup.object().shape({
    name: yup.string().required("El nombre del unidad de negocio es requerido"),
    enterpriseKey: yup
      .string()
      .required("El nombre del Laboratorio es requerido"),
    idCountry: yup.string().required("Selecciona un país"),
    idBillingUnit: yup
      .string()
      .required("La unidad de facturación es requerida")
  });

  const editSchema = yup.object().shape({
    name: yup
      .string()
      .required("El nombre de la unidad de negocio es requerido"),
    status: yup.string().required("Selecciona un estado")
  });

  const schema = rowData.id ? editSchema : createSchema;

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: rowData.name || "",
      idCountry: rowData.idCountry || "",
      enterpriseKey: rowData.enterpriseKey || "",
      status: rowData.status ?? 1
    }
  });

  useEffect(() => {
    if (open) {
      reset({
        name: rowData.name || "",
        idCountry: rowData.idCountry || "",
        enterpriseKey: rowData.enterpriseKey,
        status: rowData.status ?? 1
      });
    }
  }, [open, rowData, reset]);

  const handleClose = () => {
    setOpenCreate(false);
    setOpenCreateBillingUnit(false);
  };

  const handleFormSubmit = (formData) => {
    if (rowData.id) {
      delete formData.idCountry;
    }
    rowData.id
      ? onSubmitFuntion({ id: rowData.id, ...formData })
      : onSubmitFuntion({ id: 0, ...formData });
    reset();
    onClose();
  };

  const createCountry = (formData) => {
    callApiAsync(
      () => countryCreateOrUpdate(formData),
      (data) => {
        getCountrysSelect();
      }
    );
  };
  const getCurrencySelect = () => {
    callApiAsync(
      () => getCurrency(),
      (data) => {
        setCurrencyOptions(transformCurrencyData(data));
      }
    );
  };

  const createBillingUnit = (formData) => {
    console.log("createBillingUnit", formData);
    callApiAsync(
      () => billingUnitsCreateOrUpdate(formData),
      (data) => {
        getBillingUnitsSelect();
        getSysEnterpriseSelect();
      }
    );
  };

  return (
    <div>
      <ModalComponent
        open={open}
        onClose={onClose}
        onSubmit={handleSubmit(handleFormSubmit)}
        title={
          rowData.id ? "Editar unidad de negocio" : "Crear unidad de negocio"
        }
      >
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div>
            <TextInput
              name="name"
              control={control}
              label="Nombre de la unidad de negocio"
              margin="20px 0 0 0 "
            />
          </div>
          {!rowData.id && (
            <div className="HContainer mt-10">
              <SearchableSelectInput
                name="enterpriseKey"
                control={control}
                label="Laboratorio a asociar"
                options={sysEnterpriseSelect}
              />
            </div>
          )}
          <div className="HContainer mt-10">
            <SearchableSelectInput
              name="idCountry"
              control={control}
              label="País"
              options={countrys}
              margin="20px 0 0 0 "
            />
            <ButtonComponent
              onClick={() => setOpenCreate(true)}
              variant="contained"
              color="secondary"
              height="56px"
              margin="0 0 0 10px"
            >
              <div className="HContainer a-center">
                <img
                  className="btn-icon icon-margin-r-15"
                  src={icons.plus}
                  alt="icon"
                />
                <p>Agregar país</p>
              </div>
            </ButtonComponent>
          </div>
          {!rowData.id && (
            <div className="HContainer mt-10">
              <SearchableSelectInput
                name="idBillingUnit"
                control={control}
                label="Unidad de facturación"
                options={billingUnits}
              />
              <ButtonComponent
                onClick={() => setOpenCreateBillingUnit(true)}
                variant="contained"
                color="secondary"
                height="56px"
                margin="0 0 0 10px"
              >
                <div className="HContainer a-center">
                  <img
                    className="btn-icon icon-margin-r-15"
                    src={icons.plus}
                    alt="icon"
                  />
                  <p>Agregar U. de facturación</p>
                </div>
              </ButtonComponent>
            </div>
          )}
          {rowData.id && (
            <div className="HContainer mt-20">
              <SelectInput
                name="status"
                control={control}
                label="Estado"
                options={status_select}
                defaultValue={rowData.status ?? 1}
              />
            </div>
          )}
          <div className="HContainer j-center mt-20">
            <ButtonComponent
              type="submit"
              backgroundColor="#F6C079"
              textColor="#000000"
              width="160px"
            >
              Guardar
            </ButtonComponent>
          </div>
        </form>
      </ModalComponent>

      <CreateBillingUnitModal
        billingUnitData={billingUnits}
        open={openCreateBillingUnit}
        onClose={handleClose}
        currencyOptions={currencyOptions}
        onSubmitFunction={createBillingUnit}
      />

      <CreateCountryModal
        countrysData={countrys}
        open={openCreate}
        onClose={handleClose}
        onSubmitFuntion={createCountry}
      />
    </div>
  );
};

export default CreateEditBusinessUnitModal;
