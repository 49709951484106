import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Link, useNavigate } from "react-router-dom";
import icons from "../../constants/icons";
import { logout } from "../../redux/slices/userSlice";
import { useDispatch } from "react-redux";

const pages = [
  //{ name: "Productos", url: "/Products" },
  { name: "Unidad de negocio", url: "/BusinessUnit" },
  // { name: "Paquetes", url: "/Packages" },
  // { name: "Elementos facturación", url: "/PricingInstance" },
  { name: "Facturación", url: "/Billing" }
];

const settings = ["Cerrar Sesión"];

const Header = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "transparent", boxShadow: "none" }}
    >
      <div className="HContainer a-center">
        {/* Logo and Title */}
        <Link
          to="/PricingInstance"
          style={{
            display: "flex",
            alignItems: "center",
            textDecoration: "none"
          }}
        >
          <img
            className="w-icon-35 icon-margin-r-15"
            src={icons.bill}
            alt="icon"
            style={{ marginRight: 15 }}
          />
          <Typography
            variant="h6"
            noWrap
            sx={{
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "#006eb7",
              textDecoration: "none"
            }}
          >
            NovaBilling
          </Typography>
        </Link>

        {/* Desktop Menu */}
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", md: "flex" },
            marginLeft: 3
          }}
        >
          {pages.map((page) => (
            <Link
              key={page.name}
              to={page.url}
              style={{ textDecoration: "none" }}
            >
              <Button
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: "#006eb7",
                  display: "block",
                  "&:hover": { color: "#005493" }
                }}
              >
                {page.name}
              </Button>
            </Link>
          ))}
        </Box>

        {/* Responsive Menu Button */}
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "flex", md: "none" },
            justifyContent: "flex-end"
          }}
        >
          <IconButton
            size="large"
            aria-label="menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <img
              className="w-icon-25 icon-margin-r-15"
              src={icons.menu}
              alt="menu icon"
            />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            {pages.map((page) => (
              <Link
                key={page.name}
                to={page.url}
                style={{ textDecoration: "none" }}
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography
                    textAlign="center"
                    sx={{ color: "#006eb7", "&:hover": { color: "#004273" } }}
                  >
                    {page.name}
                  </Typography>
                </MenuItem>
              </Link>
            ))}
          </Menu>
        </Box>

        {/* User Menu */}
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Menu de usuario">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <img
                className="w-icon-25 icon-margin-r-15"
                src={icons.userMenu}
                alt="user menu icon"
              />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem
                key={setting}
                onClick={() => {
                  handleCloseUserMenu();
                  if (setting === "Cerrar Sesión") handleLogout();
                }}
              >
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </div>
    </AppBar>
  );
};

export default Header;
