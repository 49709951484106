import React, { useEffect, useMemo, useState } from "react";
import ModalComponent from "../../components/modalComponent";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextInput from "../../components/textInput";
import SelectInput from "../../components/selectInput";
import {
  actionsColumn,
  hideColumn,
  textColumn
} from "../../components/tableComponent/columns";
import TableComponent from "../../components/tableComponent";
import ButtonComponent from "../../components/button";
import icons from "../../constants/icons";
import { Divider, Grid } from "@mui/material";
import AlertComponent from "../../components/alertModal";
import {
  callApiAsync,
  changeBilledFeature,
  deleteFeaturePeriodUser,
  getCurrency,
  getUsersBilling,
  updateFeaturePeriodCall
} from "../../services/apiService";

function transformCurrencyData(currencyData) {
  return currencyData.map((item) => ({
    value: item.id,
    label: item.siglas.trim()
  }));
}

const BillingDetailsModal = ({
  rowData,
  open,
  billingPeriod,
  bussinesUnitId,
  onClose,
  getFeaturesBillingTable,
  getPeriodUserTable
}) => {
  const [userData, setUserData] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [UserSelectedRow, setUserSelectedRow] = useState({});
  const [userAlertOpen, setUserAlertOpen] = useState(false);

  const handleTableUserDelete = (row) => {
    setUserSelectedRow(row);
    setUserAlertOpen(true);
  };

  const handleDeleteUser = () => {
    deleteFeature(UserSelectedRow);
    setUserAlertOpen(false);
  };

  const columns = [
    hideColumn("id", "ID"),
    textColumn("userName", "Nombre Usuario"),
    textColumn("userEmail", "Email"),
    textColumn("roles", "Rol"),

    {
      accessorKey: "IsBillable",
      header: "Facturable",
      Cell: ({ row }) => {
        const billable = row.original.isBillable;

        return (
          <div
            className="pill pointer"
            onClick={() => changeBilled(row.original)}
            style={{ backgroundColor: billable ? "#4CAF50" : "#F44336" }}
          >
            <p className="no-margin">{billable ? "SI" : "NO"}</p>
          </div>
        );
      }
    },
    actionsColumn(
      ({ row }) => (
        <div className="HContainer">
          <ButtonComponent
            onClick={() => handleTableUserDelete(row.original)}
            variant="contained"
            backgroundColor="#fff0"
          >
            <img
              className="btn-icon w-icon-25"
              src={icons.red_delete}
              alt="icon"
            />
          </ButtonComponent>
        </div>
      ),
      100
    )
  ];

  const getUserTable = async () => {
    await callApiAsync(
      () => getUsersBilling(billingPeriod, bussinesUnitId, rowData.featureId),
      (data) => {
        setUserData(data);
      }
    );
  };

  const getCurrencySelect = () => {
    callApiAsync(
      () => getCurrency(),
      (data) => {
        setCurrencyOptions(transformCurrencyData(data));
      }
    );
  };

  const deleteFeature = async (data) => {
    const dataToDelete = { id: data.featurePeriodUserId };
    await callApiAsync(
      () => deleteFeaturePeriodUser(dataToDelete),
      (data) => {
        getUserTable();
        getFeaturesBillingTable();
        getPeriodUserTable();
      }
    );
  };

  const updateFeaturePeriod = async (data) => {
    const dataToSend = {
      id: data.id,
      unitPrice: data.unitPrice,
      discount: data.discountPercentage,
      currencyId: data.currency
    };
    await callApiAsync(
      () => updateFeaturePeriod(dataToSend),
      (data) => {
        console.log(data);
      }
    );
  };

  const changeBilled = async (data) => {
    const dataToSend = {
      id: data.featurePeriodUserId,
      isBillable: !data.isBillable
    };

    await callApiAsync(
      () => changeBilledFeature(dataToSend),
      () => {
        setUserData((prevData) =>
          prevData.map((user) =>
            user.featurePeriodUserId === data.featurePeriodUserId
              ? { ...user, isBillable: !user.isBillable }
              : user
          )
        );
        getFeaturesBillingTable();
      },
      (error) => {
        console.error("Error al cambiar el estado de facturación:", error);
      }
    );
  };

  useEffect(() => {
    if (open && rowData) {
      setValue("unitPrice", rowData.unitPrice);
      setValue("currency", rowData.currencyId);
      setValue("discountPercentage", rowData.discount);
    }
    getCurrencySelect();
    getUserTable();
  }, [open]);

  const schema = yup.object().shape({
    unitPrice: yup
      .number()
      .required("El precio unitario es requerido")
      .positive("El precio unitario debe ser mayor a 0")
      .typeError("El precio unitario debe ser un número válido"),
    currency: yup
      .string()
      .required("La moneda es requerida")
      .test(
        "currency-not-zero",
        "La moneda es requerida",
        (value) => value !== "0"
      ),
    discountPercentage: yup
      .number()
      .nullable()
      .notRequired()
      .min(0, "El porcentaje de descuento no puede ser menor a 0")
      .max(100, "El porcentaje de descuento no puede ser mayor a 100")
      .typeError("El porcentaje de descuento debe ser un número válido")
  });

  const { control, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      unitPrice: 0,
      currency: "",
      discountPercentage: 0
    }
  });

  const closeModal = () => {
    reset();
    setUserData([]);
    onClose();
  };
  const handleFormSubmit = async (formData) => {
    const dataToSend = {
      id: rowData.featurePeriodId,
      unitPrice: parseFloat(formData.unitPrice),
      discount: parseFloat(formData.discountPercentage),
      discount: parseFloat(formData.discountPercentage),
      currencyId: parseFloat(formData.currency)
    };

    await callApiAsync(
      () => updateFeaturePeriodCall(dataToSend),
      () => {
        getFeaturesBillingTable();
        closeModal();
      },
      (error) => {
        console.error("Error al actualizar:", error);
      }
    );
  };

  return (
    <ModalComponent
      open={open}
      onClose={closeModal}
      title={`${rowData.mainProduct} - ${rowData.featureKey}`}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)} className="mt-20">
        <Grid container spacing={2}>
          <Grid item md={3.5} xs={12}>
            <SelectInput
              name="currency"
              control={control}
              label="Moneda"
              options={currencyOptions}
              disabled={true}
            />
          </Grid>
          <Grid item md={3.5} alignItems={"baseline"} xs={12}>
            <TextInput
              name="unitPrice"
              control={control}
              label="Precio Unitario"
              margin="0 0 0 0"
            />
          </Grid>

          <Grid item md={3.5} alignItems={"baseline"} xs={12}>
            <TextInput
              name="discountPercentage"
              control={control}
              label="% Descuento"
              margin="0 0 0 0"
            />
          </Grid>
          <Grid item md={1.5} alignItems={"baseline"} xs={12}>
            <ButtonComponent
              type="submit"
              backgroundColor="#F6C079"
              textColor="#000000"
              height="56px"
              width="100%"
            >
              Guardar
            </ButtonComponent>
          </Grid>
        </Grid>
        {/* </div> */}
      </form>
      <div className="vm-20">
        <Divider />
      </div>
      <TableComponent
        columns={columns}
        data={userData}
        enableGlobalFilter={true}
      />
      <AlertComponent
        open={userAlertOpen}
        onClose={() => setUserAlertOpen(false)}
        onConfirm={handleDeleteUser}
        title="Confirmar Eliminación"
        message="¿Estás seguro de que deseas eliminar este usuario?"
      />
    </ModalComponent>
  );
};

export default BillingDetailsModal;
