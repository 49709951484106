import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "./App.css";
import MainLayout from "./layouts/mainLayout";
import ProtectedRoute from "./components/protectedRoute";
// import Home from "./pages/home";
import Products from "./pages/products";
import BusinessUnit from "./pages/businessUnit";
import Packages from "./pages/packagesCrud";
import PrincingInstace from "./pages/pricingInstance";
import Billing from "./pages/billing";
import Login from "./pages/login";

const LayoutRoute = ({ children }) => (
  <ProtectedRoute>
    <MainLayout>{children}</MainLayout>
  </ProtectedRoute>
);

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/Products"
            element={
              <LayoutRoute>
                <Products />
              </LayoutRoute>
            }
          />
          <Route
            path="/BusinessUnit"
            element={
              <LayoutRoute>
                <BusinessUnit />
              </LayoutRoute>
            }
          />
          <Route
            path="/Packages"
            element={
              <LayoutRoute>
                <Packages />
              </LayoutRoute>
            }
          />
          <Route
            path="/PricingInstance"
            element={
              <LayoutRoute>
                <PrincingInstace />
              </LayoutRoute>
            }
          />
          <Route
            path="/Billing"
            element={
              <LayoutRoute>
                <Billing />
              </LayoutRoute>
            }
          />

          <Route path="*" element={<Navigate to="/BusinessUnit" />} />
          <Route path="/" element={<Navigate to="/BusinessUnit" />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
