import { Button } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import CreateEditBusinessUnitModal from "./createEditBusinessUnit";
import {
  actionsColumn,
  hideColumn,
  textColumn
} from "../../components/tableComponent/columns";
import TableComponent from "../../components/tableComponent";
import "./styles.css";
import {
  businessUnitCreateOrUpdate,
  callApiAsync,
  deleteBussinesUnit,
  getBusinessUnits
} from "../../services/apiService";
import AlertComponent from "../../components/alertModal";
import ToastNotification from "../../components/toastNotification";
import ButtonComponent from "../../components/button";
import icons from "../../constants/icons";
import StatusPill from "../../components/statusPill";

const BusinessUnit = () => {
  const [bussinesUnit, setBussinesUnit] = useState([]);
  const [openCreateEdit, setOpenCreateEdit] = useState(false);

  const [selectedRow, setSelectedRow] = useState({});

  const [alertOpen, setAlertOpen] = useState(false);

  const [toastMessage, setToastMessage] = useState("");
  const [toastOpen, setToastOpen] = useState(false);
  const [toastSeverity, setToastSeverity] = useState("warning");

  const getBusinessUnitTable = () => {
    callApiAsync(getBusinessUnits, (data) => {
      setBussinesUnit(data);
    });
  };

  useEffect(() => {
    getBusinessUnitTable();
  }, []);

  const createUpdateBussinessUnit = async (businessUnit) => {
    try {
      await callApiAsync(
        () => businessUnitCreateOrUpdate(businessUnit),
        () => {
          setToastSeverity("success");
          setToastMessage("Unidad de negocio creada exitosamente.");
          setToastOpen(true);

          getBusinessUnitTable();
        },
        (error) => {
          setToastSeverity("error");
          setToastMessage(error);
          setToastOpen(true);
        }
      );
    } catch (error) {
      const errorMessage = error?.message || error;
      setToastSeverity("error");
      setToastMessage(errorMessage);
      setToastOpen(true);
    } finally {
      handleClose();
    }
  };

  const deleteBussinesUnitBtn = (featureData) => {
    callApiAsync(
      () => deleteBussinesUnit(featureData.id),
      () => {
        setToastMessage("se elimino la unidad de negocio exitosamente.");
        setToastSeverity("success");
        setToastOpen(true);

        getBusinessUnitTable();
      },
      (err) => {
        const errorMessage = err?.message || err;
        setToastSeverity("error");
        setToastMessage(errorMessage);
        setToastOpen(true);
      }
    );
  };

  const handleCreateEdit = (row) => {
    if (!row) {
      setSelectedRow({});
      setOpenCreateEdit(true);
      return;
    }
    console.log(row);
    setSelectedRow(row);
    setOpenCreateEdit(true);
  };

  const handleClose = () => {
    setOpenCreateEdit(false);
  };

  const handleDelete = () => {
    deleteBussinesUnitBtn(selectedRow);
    setSelectedRow({});
  };

  const handleTableDelete = (row) => {
    setSelectedRow(row);
    setAlertOpen(true);
  };

  const actionBtn = ({ row }) => (
    <div className="HContainer">
      <ButtonComponent
        onClick={() => handleCreateEdit(row.original)}
        variant="contained"
        backgroundColor="#fff0"
      >
        <img className="btn-icon" src={icons.blue_edit} alt="icon" />
      </ButtonComponent>
      {/* <ButtonComponent
        onClick={() => handleTableDelete(row.original)}
        variant="contained"
        backgroundColor="#fff0"
      >
        <img className="btn-icon" src={icons.red_delete} alt="icon" />
      </ButtonComponent> */}
    </div>
  );

  const columns = useMemo(
    () => [
      hideColumn("idBillingUnit", "idBillingUnit"),
      textColumn("billingUnitName", "Unidad de facturación "),
      textColumn("name", "Nombre de la Unidad de negocio", 200),
      hideColumn("idCountry", ""),
      textColumn("countryName", "País", 100),
      hideColumn("enterpriseKey	", "Clave empresarial", 100),
      textColumn("enterpriseName", "Unidad de negocio sistema"),
      {
        id: "status",
        header: "Estado",
        size: 100,
        Cell: ({ row }) => (
          <StatusPill
            backgroundColor={row.original.status === 1 ? "#4ADE80" : "#D9D9D9"}
            textColor={"#000000"}
          >
            {row.original.status === 1 ? "Activo" : "Inactivo"}
          </StatusPill>
        )
      },
      actionsColumn(actionBtn)
    ],
    []
  );

  return (
    <div>
      <h1>Unidad de negocio</h1>
      <div>
        <TableComponent
          columns={columns}
          data={bussinesUnit}
          enableGlobalFilter={true}
          initialState={{
            sorting: [
              {
                id: "billingUnitName",
                desc: false 
              }
            ]
          }}
          headerButtons={() =>
            <>
              <ButtonComponent
                onClick={() => handleCreateEdit()}
                type="submit"
                variant="contained"
                backgroundColor="#F6C079"
                textColor="#000000"
                margin="15px 0"
              >
                <div className="HContainer a-center">
                  <img
                    className="btn-icon icon-margin-r-15"
                    src={icons.plus}
                    alt="icon"
                  />
                  <p>Crear unidad de negocio</p>
                </div>
              </ButtonComponent>
            </>
          }
        />
      </div>
      <CreateEditBusinessUnitModal
        tableData={bussinesUnit}
        rowData={selectedRow}
        open={openCreateEdit}
        onClose={handleClose}
        onSubmitFuntion={createUpdateBussinessUnit}
      />
      <AlertComponent
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        onConfirm={handleDelete}
        title="Confirmar Eliminación"
        message="¿Estás seguro de que deseas eliminar esta unidad de negocio? Esta acción no se puede deshacer."
      />
      <ToastNotification
        open={toastOpen}
        handleClose={() => setToastOpen(false)}
        message={toastMessage}
        severity={toastSeverity}
      />
    </div>
  );
};

export default BusinessUnit;
